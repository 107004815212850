.hidden {
  display: none;
}

.app{
  padding-left: 50px;
  padding-right: 50px;
  background-color: rgb(247,246,235);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  gap: 20px;
}

u {
  text-decoration: rgb(108,157,111) wavy underline;
}

.headerContainer {
  display: flex;
  gap: 30px;
  align-items: flex-end;
  padding-top: 30px;
  justify-content: center;
  width: 75%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 15px;  
  padding-bottom: 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.logo {
  width: 15vw;
  flex-grow: 0;
}

.bio {
  font-weight: 600;
  font-size: 1.75em;
  word-wrap: normal;
  flex-grow: 0;
}

.subBioContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.bioDesc {
  font-weight: 400;
  font-size: 0.6em;
}

.contact {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 0px;
}

.warning {
  padding: 0.75em 1em;
  background-color: #ceebd0;
  border-radius: 10px;
  width: max-content;
  box-shadow: 8px 8px 3px 1px #8cb88f;
}

.filterSection {
  display: flex;
  flex-direction: column;
  text-align: center;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px;
  width: 100%;
  background-color: rgb(247,246,235);
  margin: 0;
}

.filters {
  display: flex;
  align-self: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.filterButtonOnRed {
  border: solid rgb(146,62,57);
  color: rgb(247,246,235);
  padding: 5px 15px;
  border-radius: 8px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.filterButtonOnBlue {
  border: solid rgb(32,129,166);
  color: rgb(247,246,235);
  padding: 5px 15px;
  border-radius: 8px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.filterButtonOnGreen {
  border: solid rgb(108,157,111);
  color: rgb(247,246,235);
  padding: 5px 15px;
  border-radius: 8px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.filterButtonOffRed {
  border: solid rgb(146,62,57);
  color: rgb(146,62,57);
  padding: 5px 15px;
  border-radius: 8px;
}

.filterButtonOffBlue {
  border: solid rgb(32,129,166);
  color: rgb(32,129,166);
  padding: 5px 15px;
  border-radius: 8px;
}

.filterButtonOffGreen {
  border: solid rgb(108,157,111);
  color: rgb(108,157,111);
  padding: 5px 15px;
  border-radius: 8px;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  display: flex;
  flex-direction: column;
}

.filterSectionTitle {
  font-weight: 500;
  font-size: 1.15em;
  text-align: center;
  padding: 10px 10px 15px 10px;
}

.sectionTitle {
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  padding: 10px 10px 15px 10px;
}

.defaultPhoto {
  padding: 6px; 
  border-radius: 10px;
  width: 100%; 
  height: auto; 
  display: block;
  object-fit: cover;
}

.modal {
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 1;
  background: #f7f6eb;
  border-radius: 8px;
  padding: 20px 20px;
  width: 80vw;
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  z-index: 2000;
}

.modalRed {
  border: thick solid rgb(146,62,57);
}

.modalBlue {
  border: thick solid rgb(32,129,166);
}

.modalGreen {
  border: thick solid rgb(108,157,111);
}

.closeButton {
  background-color: rgb(247,246,235);
  align-self: flex-end;
  border: none;
  outline: none;
}

.imgContainer {
  /* width: 600px; */
  width: fit-content;
  /* height: 650px; */
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: center;
}

.modalPhoto {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.photoRed {
  background-color: rgb(146,62,57);
}

.photoBlue {
  background-color: rgb(32,129,166);	
}

.photoGreen {
  background-color: rgb(108,157,111);	
}

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  margin: 0;
}

.galleryItem {
  flex: 1 1 auto;
  max-width: 25%;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .galleryItem {
    flex: 1 1 auto;
    max-width: 33%;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .galleryItem {
    max-width: 75%;
  }

  .logo {
    width: 25vw;
    padding-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .app {
    overflow-x: hidden
  }
  
  .bio {
    font-weight: 600;
    font-size: 1.25em;
    text-align: center;
  }

  .subBio {
    font-size: 1.1em;
    text-align: center;
  }

  .header {
    padding-bottom: 6px;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    width: 90%;
    gap: 20px;
  }

  .galleryItem {
    max-width: 90%;
  }

  .logo {
    width: 60vw;
    padding-bottom: 0px;
  }

  .contact {
    padding-top: 0px;
  }
}

